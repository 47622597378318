import templateUrl from './components-toolbar.html'
import './components-toolbar.scss'

const DemoComponentsToolbarComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsToolbarComponent {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsToolbarComponent'
    }

    $onInit () {

    }
  }
}

export default DemoComponentsToolbarComponent
