import templateUrl from './components-typography.html'
import './components-typography.scss'

const DemoComponentsTypographyComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsTypography {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsTypographyComponent'
    }
  }
}

export default DemoComponentsTypographyComponent
