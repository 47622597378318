import templateUrl from './components-grid.html'
import './components-grid.scss'

const DemoComponentsGridComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsGridComponent {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsGridComponent'
    }

    $onInit () {
      this.grid = {
        cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        gaps: [0, 8, 16, 32],
        breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'gt-xs', 'gt-sm', 'gt-md', 'gt-lg']
      }
    }
  }
}

export default DemoComponentsGridComponent
