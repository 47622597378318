import DemoComponentsModalComponent from './components-modal.component'

const DemoComponentsModalModule = angular.module('cdbl.components-modal', [

])
  .component('cdblDemoComponentsModal', DemoComponentsModalComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.modal', {
        url: '/modal',
        template: '<cdbl-demo-components-modal></cdbl-demo-components-modal>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsModalModule
