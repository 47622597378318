import DemoComponentsCardComponent from './components-card.component'

const DemoComponentsCardModule = angular.module('cdbl.components-card', [

])
  .component('cdblDemoComponentsCard', DemoComponentsCardComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.card', {
        url: '/card',
        template: '<cdbl-demo-components-card></cdbl-demo-components-card>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsCardModule
