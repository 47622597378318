import DemoComponentsComponent from './demo-components.component'

import DemoComponentsTypographyModule from './components-typography'
import DemoComponentsButtonsModule from './components-buttons'
import DemoComponentsFormModule from './components-form'
import DemoComponentsReadmeModule from './components-readme'
import DemoComponentsUncategorizedModule from './components-uncategorized'
import DemoComponentsIconsModule from './components-icons'
import DemoComponentsCollapsibleModule from './components-collapsible'
import DemoComponentsSectionModule from './components-section'
import DemoComponentsCardModule from './components-card'
import DemoComponentsLayoutModule from './components-layout'
import DemoComponentsModalModule from './components-modal'
import DemoComponentsToolbarModule from './components-toolbar'
import DemoComponentsContainerModule from './components-container'
import DemoComponentsPageModule from './components-page'
import DemoComponentsPaginationModule from './components-pagination'
import DemoComponentsFilterWidgetModule from './components-filter-widget'
import DemoComponentsUtilitiestModule from './components-utilities'
import DemoComponentsGridModule from './components-grid'


const DemoComponentsModule = angular.module('dev.demo-components', [
  uiRouter,
  DemoComponentsTypographyModule,
  DemoComponentsButtonsModule,
  DemoComponentsFormModule,
  DemoComponentsReadmeModule,
  DemoComponentsUncategorizedModule,
  DemoComponentsIconsModule,
  DemoComponentsCollapsibleModule,
  DemoComponentsSectionModule,
  DemoComponentsCardModule,
  DemoComponentsLayoutModule,
  DemoComponentsModalModule,
  DemoComponentsLayoutModule,
  DemoComponentsToolbarModule,
  DemoComponentsContainerModule,
  DemoComponentsPageModule,
  DemoComponentsPaginationModule,
  DemoComponentsFilterWidgetModule,
  DemoComponentsUtilitiestModule,
  DemoComponentsGridModule
])
  .component('cdblDemoComponents', DemoComponentsComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('components', {
        url: '/demo-components',
        abstract: true,
        template: '<cdbl-demo-components></cdbl-demo-components>',
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isAuthenticated')
          },
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsModule

