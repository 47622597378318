const DevService = class DevService {
  constructor () {
    'ngInject'
    this.identify = 'DevService'
    this.actions = []
  }

  registerAction (actionConfig) {
    const existingAction = this.actions.find(a => a.name === actionConfig.name)
    if (existingAction) {
      this.actions[existingAction.name] = actionConfig
    } else {
      this.actions.push(actionConfig)
    }
  }
}
export default DevService
