import DemoAttachmentsComponent from './demo-attachments.component'

const DemoAttachmentsModule = angular.module('cdbl.demo-attachments', [

])
  .component('cdblDemoAttachments', DemoAttachmentsComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('demo-attachments', {
        url: '/demo-attachments',
      // component: 'about'
        template: '<cdbl-demo-attachments></cdbl-demo-attachments>',
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isAuthenticated')
          },
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('users.dashboard.inProgress')
            }
          }
        }
      })
  })
  .name

export default DemoAttachmentsModule
