import DemoComponentsPaginationComponent from './components-pagination.component'

const DemoComponentsPaginationModule = angular.module('cdbl.components-pagination', [

])
  .component('cdblDemoComponentsPagination', DemoComponentsPaginationComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.pagination', {
        url: '/pagination',
        template: '<cdbl-demo-components-pagination></cdbl-demo-components-pagination>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsPaginationModule
