import templateUrl from './dev-modal.html'
import './dev-modal.scss'

const DevModalComponent = {
  bindings: {
    entities: '<'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ProjectDevModal {
    constructor (ProjectService) {
      'ngInject'
      this._identify = 'DevModalComponent'

      this.ProjectService = ProjectService
    }

    $onInit () {

    }
  }
}

export default DevModalComponent
