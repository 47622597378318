import templateUrl from './components-utilities.html'
import './components-utilities.scss'

const DemoComponentsUtilitiesComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsUtilities {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsUtilitiesComponent'
    }

    $onInit () {
      this.showUtilitiesContainer = false
      this.buttonActive = false
      this.utilitiesList = [
        'utilities-save',
        'utilities-search',
        'utilities-sort',
        'utilities-spenditure',
        'utilities-star-empty',
        'utilities-star-full',
        'utilities-task',
        'utilities-timezone',
        'utilities-user',
        'utilities-paperclip-bold',
        'utilities-paperclip-default',
        'utilities-pencil-default',
        'utilities-play',
        'utilities-plus',
        'utilities-public-default',
        'utilities-question',
        'utilities-reorder-default',
        'utilities-retainer',
        'utilities-hourglass',
        'utilities-info',
        'utilities-lock-closed',
        'utilities-lock-open',
        'utilities-megaphone',
        'utilities-music',
        'utilities-comment',
        'utilities-delete-default',
        'utilities-delete-small',
        'utilities-download',
        'utilities-estimate',
        'utilities-external-default',
        'utilities-external-small',
        'utilities-eye-closed-default',
        'utilities-eye-open-default',
        'utilities-filter',
        'utilities-flag',
        'utilities-heart',
        'utilities-caret-down-default',
        'utilities-caret-down-small',
        'utilities-caret-left-default',
        'utilities-caret-left-small',
        'utilities-caret-right-default',
        'utilities-caret-right-small',
        'utilities-caret-up-default',
        'utilities-caret-up-small',
        'utilities-checkbox-checkmark-default',
        'utilities-checkmark-circle-default',
        'utilities-checkmark-default',
        'utilities-checkmark-small',
        'utilities-close-default',
        'utilities-close-small',
        'utilities-cog',
        'utilities-bin-default',
        'utilities-budget-bag',
        'utilities-budget-wave',
        'utilities-budget',
        'utilities-calculator',
        'utilities-calendar',
        'utilities-camera',
        'utilities-card',
      ]
    }
  }
}

export default DemoComponentsUtilitiesComponent
