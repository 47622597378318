import templateUrl from './components-card.html'
import './components-card.scss'

const DemoComponentsCardComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsCardComponent {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsCardComponent'
    }
  }
}

export default DemoComponentsCardComponent
