import templateUrl from './components-pagination.html'
import './components-pagination.scss'

const DemoComponentsPaginationComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsPaginationComponent {
    constructor (PaginationService) {
      'ngInject'
      this._identify = 'DemoComponentsPaginationComponent'
      this.PaginationService = PaginationService
    }

    $onInit () {
      this.pagination = this.PaginationService.init({
        itemsPerPage: 1,
        paginationArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        mode: 'array' // 'server'
      })
    }

    onPageChangeHandler (page) {
      alert(`Page ${page + 1}`)
    }
  }
}

export default DemoComponentsPaginationComponent
