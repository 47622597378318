import debugComponent from './debug.component'

const debugModule = angular.module('dev.debug', [
  uiRouter
])

  .component('cdblDebug', debugComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('debug', {
        url: '/debug',
      // component: 'debug',
        template: '<cdbl-debug></cdbl-debug>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default debugModule

