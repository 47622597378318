import DemoComponentsUncategorizedComponent from './components-uncategorized.component'

const DemoComponentsUncategorizedModule = angular.module('cdbl.components-uncategorized', [

])
  .component('cdblDemoComponentsUncategorized', DemoComponentsUncategorizedComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.uncategorized', {
        url: '/uncategorized',
        template: '<cdbl-demo-components-uncategorized></cdbl-demo-components-uncategorized>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsUncategorizedModule
