import DemoComponentsTypographyComponent from './components-typography.component'

const DemoComponentsTypographyModule = angular.module('cdbl.components-typography', [

])
  .component('cdblDemoComponentsTypography', DemoComponentsTypographyComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.typography', {
        url: '',
        template: '<cdbl-demo-components-typography></cdbl-demo-components-typography>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsTypographyModule
