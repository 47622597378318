import DemoComponentsContainerComponent from './components-container.component'

const DemoComponentsContainerModule = angular.module('cdbl.components-container', [

])
  .component('cdblDemoComponentsContainer', DemoComponentsContainerComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.container', {
        url: '/container',
        template: '<cdbl-demo-components-container></cdbl-demo-components-container>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsContainerModule
