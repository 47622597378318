import DemoComponentsFilterWidgetComponent from './components-filter-widget.component'

const DemoComponentsFilterWidgetModule = angular.module('cdbl.components-filter-widget', [

])
  .component('cdblDemoComponentsFilterWidget', DemoComponentsFilterWidgetComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.filter-widget', {
        url: '/filter-widget',
        template: '<cdbl-demo-components-filter-widget></cdbl-demo-components-filter-widget>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsFilterWidgetModule
