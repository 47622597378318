import templateUrl from './components-icons.html'
import './components-icons.scss'

const DemoComponentsIconsComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsIcons {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsIconsComponent'
    }

    $onInit () {
      this.showIconContainer = false
      this.buttonActive = false
      this.iconsList = [
        'icon-save',
        'icon-search',
        'icon-sort',
        'icon-spenditure',
        'icon-star-empty',
        'icon-star-full',
        'icon-task',
        'icon-timezone',
        'icon-user',
        'icon-paperclip-bold',
        'icon-paperclip-default',
        'icon-pencil-default',
        'icon-play',
        'icon-plus',
        'icon-public-default',
        'icon-question',
        'icon-reorder-default',
        'icon-retainer',
        'icon-hourglass',
        'icon-info',
        'icon-lock-closed',
        'icon-lock-open',
        'icon-megaphone',
        'icon-music',
        'icon-comment',
        'icon-delete-default',
        'icon-delete-small',
        'icon-download',
        'icon-estimate',
        'icon-external-default',
        'icon-external-small',
        'icon-eye-closed-default',
        'icon-eye-open-default',
        'icon-filter',
        'icon-flag',
        'icon-heart',
        'icon-caret-down-default',
        'icon-caret-down-small',
        'icon-caret-left-default',
        'icon-caret-left-small',
        'icon-caret-right-default',
        'icon-caret-right-small',
        'icon-caret-up-default',
        'icon-caret-up-small',
        'icon-checkbox-checkmark-default',
        'icon-checkmark-circle-default',
        'icon-checkmark-default',
        'icon-checkmark-small',
        'icon-close-default',
        'icon-close-small',
        'icon-cog',
        'icon-bin-default',
        'icon-budget-bag',
        'icon-budget-wave',
        'icon-budget',
        'icon-calculator',
        'icon-calendar',
        'icon-camera',
        'icon-card',
      ]
    }
  }
}

export default DemoComponentsIconsComponent
