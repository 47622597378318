import templateUrl from './components-buttons.html'
import './components-buttons.scss'

const DemoComponentsButtonsComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsButtons {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsButtonsComponent'
    }
  }
}

export default DemoComponentsButtonsComponent
