import DemoComponentsSectionComponent from './components-section.component'

const DemoComponentsSectionModule = angular.module('cdbl.components-section', [

])
  .component('cdblDemoComponentsSection', DemoComponentsSectionComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.section', {
        url: '/section',
        template: '<cdbl-demo-components-section></cdbl-demo-components-section>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsSectionModule
