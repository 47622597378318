import templateUrl from './components-filter-widget.html'
import './components-filter-widget.scss'

const DemoComponentsFilterWidgetComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsFilterWidgetComponent {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsFilterWidgetComponent'
    }

    $onInit () {
      this.sortOptions = [
        {
          id: 'created_at asc',
          name: 'Ascending'
        },
        {
          id: 'created_at desc',
          name: 'Descending',
          default: true
        }
      ]

      this.filterData = {
        sort: this.sortOptions.find(sOption => sOption.default).id
      }
    }

    onFilterApplyHandler ($event) {
      console.log('[DemoComponentsFilterWidgetComponent] > onFilterApplyHandler', $event)
      this.filterData.sort = $event.filter.sort
    }
    onFilterClearHandler ($event) {
      console.log('[DemoComponentsFilterWidgetComponent] > onFilterClearHandler', $event)
      this.filterData = {}
      this.filterData.sort = $event.filter.sort
    }
  }
}

export default DemoComponentsFilterWidgetComponent
