import templateUrl from './debug.html'
import './debug.scss'

const debugComponent = {
  bindings: {},
  templateUrl,
  controller: class DebugController {
    constructor ($state, $timeout, Configuration, AuthService, UserService) {
      'ngInject'
      this.$state = $state
      this.$timeout = $timeout
      this.Configuration = Configuration
      this.AuthService = AuthService
      this.UserService = UserService

      this.name = 'debug'
      this.state = $state.current.name
    }

    $onInit () {

    }
  }
}

export default debugComponent
