import CONFIG from './app.config.staging.js'

// changes for develop
CONFIG.CDBL_ENV = 'develop' // will be overriden by webpack
CONFIG.apiUrl = 'https://api.develop.codeable.io'
CONFIG.pusher = {
  appKey: '3f1ba9887bfab0ab61c8',
  authEndpoint: 'https://api.develop.codeable.io/pusher/auth'
}
CONFIG.googleClientId = '216260975019-jmkva34qhoqnduufpmaiek25b57lc8ne.apps.googleusercontent.com'
export default CONFIG
