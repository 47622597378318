import templateUrl from './components-readme.html'
import './components-readme.scss'

const DemoComponentsReadmeComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsReadme {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsReadmeComponent'
    }
  }
}

export default DemoComponentsReadmeComponent
