import DemoComponentsButtonsComponent from './components-buttons.component'

const DemoComponentsButtonsModule = angular.module('cdbl.components-buttons', [

])
  .component('cdblDemoComponentsButtons', DemoComponentsButtonsComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.buttons', {
        url: '/buttons',
        template: '<cdbl-demo-components-buttons></cdbl-demo-components-buttons>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsButtonsModule
