import DemoComponentsUtilitiesComponent from './components-utilities.component'

const DemoComponentsUtilitiesModule = angular.module('cdbl.components-utilities', [

])
  .component('cdblDemoComponentsUtilities', DemoComponentsUtilitiesComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.utilities', {
        url: '/utilities',
        template: '<cdbl-demo-components-utilities></cdbl-demo-components-utilities>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsUtilitiesModule
