import DemoComponentsReadmeComponent from './components-readme.component'

const DemoComponentsReadmeModule = angular.module('cdbl.components-readme', [

])
  .component('cdblDemoComponentsReadme', DemoComponentsReadmeComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.readme', {
        url: '/readme',
        template: '<cdbl-demo-components-readme></cdbl-demo-components-readme>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsReadmeModule
