import templateUrl from './demo-attachments.html'
import './demo-attachments.scss'
import { MissingArgumentsError } from '@/app/common/errors'

const DemoAttachmentsComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoAttachments {
    constructor ($q, $http, $timeout, $window, AttachmentsService, ErrorsService) {
      'ngInject'
      this._identify = 'DemoAttachmentsComponent'

      this.$q = $q
      this.$http = $http
      this.$timeout = $timeout
      this.$window = $window
      this.AttachmentsService = AttachmentsService
      this.ErrorsService = ErrorsService

      this.elFileUpload = null
      this.listenerFileUpload = null
      this.autoUpload = false

      this.projectId = 224 // TODO: remove
      this.project = {
        id: this.projectId
      }
      this.fileList = null // TODO: remove
      this.wrappedFileList = null // TODO: remove
      this.projectAttachmentList = null

      this.errorHandler = ErrorsService.createErrorHandler()
    }

    $onInit () {
      console.log('[DemoAttachmentsComponent] > $onInit > $http', this.$http)
    }

    $onDestroy () {
      this.removeListeners()
    }


    async onSelect ($event) {
      console.log('[DemoAttachmentsComponent] > onSelect > $event', $event)
      // this.uploadFiles(this.projectId, $event.queueList)
      await $event.uploader()
        .then(response => {
          console.log('TADA', response)
        })
      this.$timeout()
      console.log('FINISHED UPLOADING')
    }

    onUpload ($event) {
      console.log('[DemoAttachmentsComponent] > onUpload > $onUpload', $event)
    }

    // ------- File upload button
    addListeners (projectId, autoUpload = false) {
      const vm = this
      this.elFileUpload = document.getElementById('cdblFileUpload') // TODO make it configurable and support multiple upload buttons on same page
      this.elFileUpload.style.display = 'none'
      this.listenerFileUpload = function () {
        const fileList = Array.from(this.files) // convert iterable object to array
        vm.$timeout(vm.onFileSelectChangeHandler(projectId, autoUpload, fileList))
      }
      this.elFileUpload.addEventListener('change', this.listenerFileUpload, false)
    }

    removeListeners () {
      if (this.elFileUpload) {
        this.elFileUpload.removeEventListener('change', this.listenerFileUpload)
      }
    }

    openFileSelectDialog (projectId, autoUpload = false) {
      this.errorHandler.reset()
      this.removeListeners() // remove existing listeners
      this.addListeners(projectId, autoUpload)    // attach new listeners
      this.elFileUpload.click() // invoke upload dialog
    }

    openFileSelectDialogAndAutoUpload () {
      this.openFileSelectDialog(this.projectId, true)
    }

    async onFileSelectChangeHandler (projectId = null, autoUpload = false, fileList = []) {
      if (!projectId) {
        throw new MissingArgumentsError()
      }

      this.fileList = fileList // TODO: remove
      console.log('[DemoAttachmentsComponent] > onFileSelectChange', fileList)
      if (autoUpload) {
        console.log('[DemoAttachmentsComponent] > onFileSelectChange > START uploadFiles')
        await this.uploadFiles(projectId, fileList)
          .then(response => {
            console.log('[DemoAttachmentsComponent] > onFileSelectChange > RESOLVE uploadFiles', response)
          })
          .catch(err => { console.error(err) })
        console.log('[DemoAttachmentsComponent] > onFileSelectChange > END uploadFiles')
      }
    }
    // ---

    async uploadFiles (projectId, fileList) {
      if (!projectId || !fileList) {
        throw new MissingArgumentsError()
      }

      console.log('[DemoAttachmentsComponent] > uploadFiles')
      let filesWithValidUrls = null
      if (fileList.length > 0) {
        console.log('[DemoAttachmentsComponent] > uploadFiles > START getAttachmentUploadSignedUrls')
        await this.AttachmentsService
          .getAttachmentUploadSignedUrls(projectId, fileList)
          .then(response => {
            filesWithValidUrls = response.filter(f => f.url)
            const errors = response
              .filter(f => f.error)
              .map(f => this.errorHandler.stringToErrorObject(f.error))

            this.errorHandler.add(errors) // TODO show modal

            console.log('[DemoAttachmentsComponent] > uploadFiles > RESOLVE getAttachmentUploadSignedUrls', response)
          })
          .catch(err => { this.errorHandler.abort(err) })
        console.log('[DemoAttachmentsComponent] > uploadFiles > END getAttachmentUploadSignedUrls')
        this.$timeout()

        if (this.errorHandler.aborted) {
          return
        }

        this.wrappedFileList = this.AttachmentsService.wrapFileList(filesWithValidUrls, fileList)

        console.log('[DemoAttachmentsComponent] > uploadFiles > START uploadToProvider')
        await this.uploadToProvider(projectId, this.wrappedFileList)
          .then(response => {
            console.log('[DemoAttachmentsComponent] > uploadFiles > RESOLVE uploadToProvider', response)
          })
          .catch(err => { this.errorHandler.abort(err) })
        console.log('[DemoAttachmentsComponent] > uploadFiles > END uploadToProvider')
        this.$timeout()
      }
    }

    async uploadToProvider (projectId, wrappedFileList) {
      console.log('[DemoAttachmentsComponent] > uploadToProvider > START uploadFileToGCS')
      if (!projectId || !wrappedFileList) {
        throw new MissingArgumentsError()
      }

      const promises = []
      if (wrappedFileList.length > 0) {
        wrappedFileList.forEach(wrappedFile => {
          wrappedFile.started = true
          wrappedFile.progress = 0
          const p = this.AttachmentsService
            .uploadFileToGCS(wrappedFile)
            .then(async response => {
              wrappedFile.uploaded = true
              console.log('[DemoAttachmentsComponent] > uploadToProvider > RESOLVE uploadFileToGCS', response, wrappedFile)

              console.log('[DemoAttachmentsComponent] > uploadToProvider > START attachFileToProject', response, wrappedFile)

              await this.AttachmentsService
                .postAttachmentToProject(projectId, wrappedFile.fileObject)
                .then(response => {
                  wrappedFile.synced = true
                  wrappedFile.attachment = response
                  console.log('[DemoAttachmentsComponent] > uploadToProvider > RESOLVE attachFileToProject', wrappedFile)
                })
                .catch(err => { this.errorHandler.add(err) })
              console.log('[DemoAttachmentsComponent] > uploadToProvider > END attachFileToProject', wrappedFile.fileObject.name)
              this.$timeout()

              return wrappedFile
            })
          promises.push(p)
        })
      }

      return this.$q
        .all(promises)
        // .then(response => {
        //   console.log('[DemoAttachmentsComponent] > uploadToGCS > all resolved', response)
        //   console.log('[DemoAttachmentsComponent] > uploadToGCS > all')
        // })
        .catch(err => { this.errorHandler.add(err) })
    }


    async manualGetSignedUrls () {
      console.log('[DemoAttachmentsComponent] > manualGetSignedUrls > START getAttachmentUploadSignedUrls')
      await this.AttachmentsService
        .getAttachmentUploadSignedUrls(this.projectId, this.fileList)
        .then(response => {
          const filesWithValidUrls = response.filter(f => f.url)
          const errors = response
            .filter(f => f.error)
            .map(f => this.errorHandler.stringToErrorObject(f.error))

          this.errorHandler.add(errors)

          this.wrappedFileList = this.AttachmentsService.wrapFileList(filesWithValidUrls, this.fileList)
          console.log('[DemoAttachmentsComponent] > manualGetSignedUrls > RESOLVE getAttachmentUploadSignedUrls', this.wrappedFileList)
        })
        .catch(err => { this.errorHandler.abort(err) })
      console.log('[DemoAttachmentsComponent] > manualGetSignedUrls > END getAttachmentUploadSignedUrls')
      this.$timeout()
    }

    async manualUploadOnly () {
      console.log('[DemoAttachmentsComponent] > manualUploadOnly > START uploadToProvider')
      await this.uploadToProvider(this.projectId, this.wrappedFileList)
        .then(response => {
          console.log('[DemoAttachmentsComponent] > manualUploadOnly > RESOLVE uploadToProvider', response)
        })
        .catch(err => { this.errorHandler.abort(err) })
      console.log('[DemoAttachmentsComponent] > manualUploadOnly > END uploadToProvider')
      this.$timeout()
    }

    async getProjectAttachments (projectId) {
      console.log('[DemoAttachmentsComponent] > getProjectAttachmentList > START')
      await this.AttachmentsService
        .getAttachmentList(projectId)
        .then(response => {
          this.projectAttachmentList = response
        })
        .catch(err => { console.error(err) })
      console.log('[DemoAttachmentsComponent] > getProjectAttachmentList > END')
      this.$timeout()
    }

    async downloadAttachment (projectId, attachment) {
      console.log('[DemoAttachmentsComponent] > downloadAttachment > START')
      await this.AttachmentsService
        .getAttachmentDownloadSignedUrl(projectId, attachment.id)
        .then(response => {
          console.log('[DemoAttachmentsComponent] > downloadAttachment > RESOLVE getAttachmentSignedUrl', response)
          if (response.url) {
            this.$window.open(response.url, '_blank')
          }
        })
        .catch(err => { console.error(err) })
      console.log('[DemoAttachmentsComponent] > downloadAttachment > END')
      this.$timeout()
    }

    async deleteAttachment (projectId, attachment) {
      console.log('[DemoAttachmentsComponent] > deleteAttachment > START')
      await this.AttachmentsService
        .deleteAttachment(projectId, attachment.id)
        .then(response => {
          console.log('[DemoAttachmentsComponent] > downloadAttachment > RESOLVE deleteAttachment', response)
          this.projectAttachmentList.splice(this.projectAttachmentList.findIndex(a => a.id === attachment.id), 1)
        })
        .catch(err => { console.error(err) })
      console.log('[DemoAttachmentsComponent] > deleteAttachment > END')
      this.$timeout()
    }
  }
}

export default DemoAttachmentsComponent
