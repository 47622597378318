// modules
import DevbarModule from './devbar'
import DevModalModule from './dev-modal'
import DebugModule from './debug'
import DemoComponentsModule from './demo-components'
// import DemoGridModule from './demo-grid'
// import DemoFormModule from './demo-form'
import DemoAttachmentsModule from './demo-attachments'

import DevService from './dev.service'

const devModule = angular.module('app.dev', [
  DevbarModule,
  DevModalModule,
  DebugModule,
  DemoComponentsModule,
  // DemoGridModule,
  // DemoFormModule,
  DemoAttachmentsModule
])
  .service('DevService', DevService)
  .name

// export only if development (develop, staging branch) and is not builded (served locally)
export default devModule
// export default devModule; // TODO remove this
