import DemoComponentsLayoutComponent from './components-layout.component'

const DemoComponentsLayoutModule = angular.module('cdbl.components-layout', [

])
  .component('cdblDemoComponentsLayout', DemoComponentsLayoutComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components-layout', {
        url: '/demo-components/layout',
        template: '<cdbl-demo-components-layout></cdbl-demo-components-layout>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsLayoutModule
