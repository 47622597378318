import templateUrl from './components-form.html'
import './components-form.scss'

const DemoComponentsFormComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsForm {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsFormComponent'
    }

    $onInit () {
      this.fileSelectorConfig = {
        preview: true, // only for images
        accept: 'image/png, image/jpeg',
        maxSize: 1024 * 1e+4, // max 10mb,
        multiple: true
      }

      this.selectedFiles = null


      this.formData = {
        input1: 'You look awesome',
        input2: '',
        textarea1: '',
        textarea2: '',
        textarea3: '',
        checkbox: false,
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
        checkbox5: false,
        radioGroup1: 2,
        radioGroup2: null,
        stars: 3,

        markdown: `# h1 heading
## h2 heading

paragraph
paragraph
paragraph 
        
- list item
- list item
- list item
        
[link](http://app.cdbl.local:9000/demo-components/form)`
      }

      this.datasetAutocomplete = [
        { id: 1, name: 'option 1', enabled: true },
        { id: 2, name: 'option 2', enabled: false },
        { id: 3, name: 'option 3', enabled: true },
        { id: 4, name: 'option 4', enabled: true },
        { id: 5, name: 'option 5', enabled: false }
      ]

      this.locationOptionsListApi = {
        method: 'get',
        urlSlug: '/experts/profile/locations',
        paramName: 'city',
        labelFormatter: function (option) {
          const place = []

          if (option.city) {
            place.push(option.city)
          }
          if (option.state) {
            place.push(option.state)
          }
          if (option.country) {
            place.push(option.country)
          }

          return `${place.join(', ')}`
        }
      }

      this.formConfig = {
        fields: [
          {
            label: 'What is your name?',
            name: 'fullname',
            value: '',
            type: 'input'
          },
          {
            label: 'How would you describe yourself?',
            name: 'description',
            value: '',
            type: 'textarea'
          },
          {
            label: 'What are your pronouns',
            name: 'pronouns',
            value: null,
            type: 'radio-group',
            options: [
              {
                label: 'She / Her',
                name: 'she/her'
              },
              {
                label: 'He / Him',
                name: 'he/him'
              },
              {
                label: 'They / Them',
                name: 'they/them'
              },
              {
                label: 'Other...',
                name: 'other'
              }
            ]
          },
          {
            name: 'custompronoun',
            value: '',
            type: 'input',
            conditional: () => {
              const controlField = this.formConfig.fields.find(field => field.name === 'pronouns' && field.value === 'other')
              return !controlField
            }
          },
          {
            label: 'What you like to listen?',
            name: 'music',
            value: null,
            type: 'select',
            options: [
              {
                label: 'Rock',
                name: 'rock'
              },
              {
                label: 'Metal',
                name: 'metal'
              },
              {
                label: 'Instrumental',
                name: 'instrumental'
              }
            ]
          },
          {
            label: 'What do you wish to eat for your lunch?',
            name: 'present',
            value: null,
            type: 'checkbox',
            options: [
              {
                label: 'Soup',
                value: null
              },
              {
                label: 'Main dish',
                value: null
              },
              {
                label: 'Salad',
                value: null
              },
              {
                label: 'Desert',
                value: null
              }
            ]
          },
        ]
      }
    }

    showAlert (msg = 'This could be submitted') {
      alert(msg)
    }
  }
}

export default DemoComponentsFormComponent
