import DemoComponentsCollapsibleComponent from './components-collapsible.component'

const DemoComponentsCollapsibleModule = angular.module('cdbl.components-collapsible', [

])
  .component('cdblDemoComponentsCollapsible', DemoComponentsCollapsibleComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.collapsible', {
        url: '/collapsible',
        template: '<cdbl-demo-components-collapsible></cdbl-demo-components-collapsible>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsCollapsibleModule
