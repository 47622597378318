import templateUrl from './demo-components.html'
import './demo-components.scss'

const DemoComponentsComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponents {
    constructor ($mdMedia, $scope, $window, ModalService, UIService) {
      'ngInject'
      this.name = 'demo-components'
      this.$scope = $scope
      this.$window = $window
      this.UIService = UIService
      this.ModalService = ModalService

      this.$scope.$watch(() => $mdMedia('xl'), this.updateOnWatch())
    }

    $onInit () {
      this.demoModalConfig = {
        backdrop: true,
        closeIcon: true,
        closeOnBackdrop: false,
        demoAction: false,
        longContent: false
      }
    }


    get showTriggerSidebar () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-demo-components')
      return Boolean(sidebarCtrl)
    }

    openSidebar () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-demo-components')
      if (sidebarCtrl) {
        sidebarCtrl.open()
      }
    }

    closeSidebar () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-demo-components')
      if (sidebarCtrl) {
        sidebarCtrl.close()
      }
    }

    updateOnWatch () {
      return (newValue, oldValue) => {
        this.bigScreen = newValue
        console.log('updateOnWatch', newValue, oldValue)
      }
    }

    openDemoModal () {
      const modalConfig = {
        title: 'Modal Title',
        body: 'Modal Content <strong ng-click="$ctrl.modal.customCallback()">HTML</strong>'
      }

      modalConfig.closeIcon = this.demoModalConfig.closeIcon
      modalConfig.closeOnBackdrop = this.demoModalConfig.closeOnBackdrop
      modalConfig.backdrop = this.demoModalConfig.backdrop
      if (this.demoModalConfig.demoAction) {
        modalConfig.actions = [
          {
            label: 'Close',
            class: 'primary',
            callback: modalCtrl => {
              modalCtrl.modalClose()
            }
          },
          {
            label: 'Suprise me!',
            class: 'orange',
            callback: modalCtrl => {
              this.$window.alert('SUPRISE!!!! ')
              modalCtrl.modalClose()
            }
          }
        ]
      }

      if (this.demoModalConfig.longContent) {
        modalConfig.title = `Durin's song`
        modalConfig.body = `
          <dl><dd>The world was young, the mountains green,
          </dd><dd>No stain yet on the Moon was seen,
          </dd><dd>No words were laid on stream or stone
          </dd><dd>When <a href="/wiki/Durin" title="Durin">Durin</a> woke and walked alone.
          </dd><dd>He named the nameless hills and dells;
          </dd><dd>He drank from yet untasted wells;
          </dd><dd>He stooped and looked in <a href="/wiki/Mirrormere" title="Mirrormere" class="mw-redirect">Mirrormere</a>,
          </dd><dd>And saw a crown of stars appear,
          </dd><dd>As gems upon a silver thread,
          </dd><dd>Above the shadows of his head.
          </dd></dl>


          <dl><dd>The world was fair, the mountains tall,
          </dd><dd>In Elder Days before the fall
          </dd><dd>Of mighty kings in <a href="/wiki/Nargothrond" title="Nargothrond">Nargothrond</a>
          </dd><dd>And <a href="/wiki/Gondolin" title="Gondolin">Gondolin</a>, who now beyond
          </dd><dd>The <a href="/wiki/Belegaer" title="Belegaer">Western Seas</a> have passed away:
          </dd><dd>The world was fair in <a href="/wiki/Durin%27s_Day" title="Durin's Day">Durin's Day</a>.
          </dd></dl>


          <dl><dd>A king he was on carven throne
          </dd><dd>In many-pillared halls of stone
          </dd><dd>With golden roof and silver floor,
          </dd><dd>And <a href="/wiki/Dwarf_Runes" title="Dwarf Runes">runes</a> of power upon the door.
          </dd><dd>The light of sun and star and moon
          </dd><dd>In shining lamps of crystal hewn
          </dd><dd>Undimmed by cloud or shade of night
          </dd><dd>There shone for ever fair and bright.
          </dd></dl>


          <dl><dd>There hammer on the anvil smote,
          </dd><dd>There chisel clove, and graver wrote;
          </dd><dd>There forged was blade, and bound was hilt;
          </dd><dd>The delver mined, the mason built.
          </dd><dd>There beryl, pearl, and opal pale,
          </dd><dd>And metal wrought like fishes' mail,
          </dd><dd>Buckler and corslet, axe and sword,
          </dd><dd>And shining spears were laid in hoard.
          </dd></dl>



          <dl><dd>Unwearied then were <a href="/wiki/Dwarf" title="Dwarf" class="mw-redirect">Durin's folk</a>;
          </dd><dd>Beneath the mountains music woke:
          </dd><dd>The harpers harped, the minstrels sang,
          </dd><dd>And at the gates the trumpets rang.
          </dd></dl>



          <dl><dd>The world is grey, the mountains old,
          </dd><dd>The forge's fire is ashen-cold;
          </dd><dd>No harp is wrung, no hammer falls:
          </dd><dd>The darkness dwells in <a href="/wiki/Durin" title="Durin">Durin</a>'s halls;
          </dd><dd>The <a href="/wiki/Durin%27s_Bane" title="Durin's Bane">shadow</a> lies upon his tomb
          </dd><dd>In <a href="/wiki/Moria" title="Moria">Moria</a>, in <a href="/wiki/Moria" title="Moria">Khazad-dûm</a>.
          </dd><dd>But still the sunken stars appear
          </dd><dd>In dark and windless <a href="/wiki/Mirrormere" title="Mirrormere" class="mw-redirect">Mirrormere</a>;
          </dd><dd>There lies his crown in water deep,
          </dd><dd>Till <a href="/wiki/Durin" title="Durin">Durin</a> wakes again from sleep.
          </dd></dl>
        `
      }
      this.ModalService.open(modalConfig)
    }
  },
  require: {
    AppCtrl: '^app'
  }
}

export default DemoComponentsComponent
