import templateUrl from './components-uncategorized.html'
import './components-uncategorized.scss'

const DemoComponentsUncategorizedComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsUncategorized {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsUncategorizedComponent'
    }

    $onInit () {
      this.formData = {
        stars: 3,
        circleSelect: 8,
        radioGroup1: 'moderate',
        radioGroup2: 'urgent',
      }
      this.radioGroup1 = {
        config: {
          type: 'radio-button-outline',
          horizontal: true
        },
        options: [
          {
            title: 'Flexible',
            value: 'flexible'
          },
          {
            title: 'Moderate',
            value: 'moderate'
          },
          {
            title: 'Urgent',
            value: 'urgent'
          }
        ]
      }

      this.radioGroup2 = { ...this.radioGroup1 }
      this.radioGroup2.config = {
        type: 'radio-button-outline',
        horizontal: false
      }
    }
  }
}

export default DemoComponentsUncategorizedComponent
