import DemoComponentsFormComponent from './components-form.component'

const DemoComponentsFormModule = angular.module('cdbl.components-form', [

])
  .component('cdblDemoComponentsForm', DemoComponentsFormComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.form', {
        url: '/form',
        template: '<cdbl-demo-components-form></cdbl-demo-components-form>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsFormModule
