import templateUrl from './components-layout.html'
import './components-layout.scss'

const DemoComponentsLayoutComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsLayout {
    constructor (UIService, $timeout) {
      'ngInject'
      this._identify = 'DemoComponentsLayoutComponent'
      this.UIService = UIService
      this.$timeout = $timeout
    }

    $onInit () {

    }
  }
}

export default DemoComponentsLayoutComponent
