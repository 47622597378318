import templateUrl from './components-modal.html'
import './components-modal.scss'

const DemoComponentsModalComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsModalComponent {
    constructor (ModalService) {
      'ngInject'
      this._identify = 'DemoComponentsModalComponent'
      this.ModalService = ModalService

      this.modalConfig = {
        closeOnBackdrop: false,
        closeIcon: true,
        closeButton: true,
      }
    }


    openDefaultModal () {
      this.ModalService.open({
        ...this.modalConfig,
        title: 'Lorem ipsum dolor sit amet',
        subtitle: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ligula quam, hendrerit in varius viverra, lacinia quis diam. Phasellus condimentum rutrum justo vel eleifend. Quisque ultrices risus ante, in sollicitudin mauris congue ut. Mauris nec nibh dapibus, auctor purus vel, vulputate nisi. Curabitur interdum quam ut elit eleifend, id eleifend nisl egestas. Integer ullamcorper nunc ac leo blandit aliquam. Phasellus quis nulla sit amet nibh laoreet pharetra ut eget odio. Quisque rhoncus est sed laoreet auctor. Mauris cursus tincidunt sapien, euismod ullamcorper arcu imperdiet eget. Suspendisse mauris velit, feugiat sed neque vel, viverra vestibulum mi. Duis pharetra ac purus a auctor. Curabitur pharetra nisl id nibh ornare efficitur vitae eu tellus.',
      })
    }

    // Sizes
    openSmallModal () {
      this.ModalService.open({
        ...this.modalConfig,
        size: 'small',
        title: 'Lorem ipsum dolor sit amet',
        subtitle: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ligula quam, hendrerit in varius viverra, lacinia quis diam. Phasellus condimentum rutrum justo vel eleifend. Quisque ultrices risus ante, in sollicitudin mauris congue ut. Mauris nec nibh dapibus, auctor purus vel, vulputate nisi. Curabitur interdum quam ut elit eleifend, id eleifend nisl egestas. Integer ullamcorper nunc ac leo blandit aliquam. Phasellus quis nulla sit amet nibh laoreet pharetra ut eget odio. Quisque rhoncus est sed laoreet auctor. Mauris cursus tincidunt sapien, euismod ullamcorper arcu imperdiet eget. Suspendisse mauris velit, feugiat sed neque vel, viverra vestibulum mi. Duis pharetra ac purus a auctor. Curabitur pharetra nisl id nibh ornare efficitur vitae eu tellus.',
      })
    }

    openLargeModal () {
      this.ModalService.open({
        ...this.modalConfig,
        size: 'large',
        title: 'Lorem ipsum dolor sit amet',
        subtitle: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ligula quam, hendrerit in varius viverra, lacinia quis diam. Phasellus condimentum rutrum justo vel eleifend. Quisque ultrices risus ante, in sollicitudin mauris congue ut. Mauris nec nibh dapibus, auctor purus vel, vulputate nisi. Curabitur interdum quam ut elit eleifend, id eleifend nisl egestas. Integer ullamcorper nunc ac leo blandit aliquam. Phasellus quis nulla sit amet nibh laoreet pharetra ut eget odio. Quisque rhoncus est sed laoreet auctor. Mauris cursus tincidunt sapien, euismod ullamcorper arcu imperdiet eget. Suspendisse mauris velit, feugiat sed neque vel, viverra vestibulum mi. Duis pharetra ac purus a auctor. Curabitur pharetra nisl id nibh ornare efficitur vitae eu tellus.',
      })
    }

    // Layouts
    openLayoutDefaultModal () {
      this.ModalService.open({
        ...this.modalConfig,
        title: 'Lorem ipsum dolor sit amet',
        subtitle: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      })
    }
    openLayoutCenterModal () {
      this.ModalService.open({
        ...this.modalConfig,
        layout: 'center',
        title: 'Lorem ipsum dolor sit amet',
        subtitle: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      })
    }


    // Dedicated modals
    openConfirmationModal () {
      const config = this.ModalService.generateConfig('confirmation', {
        title: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        rejectButton: {
          label: 'Custom Reject'
        },
        resolveButton: {
          label: 'Custom Resolve'
        }
      })
      this.ModalService.open(config)
        .then(response => {
          alert('resolved')
        })
        .catch(err => {
          alert('rejected', err)
        })
    }
    openSuccessModal () {
      const config = this.ModalService.generateConfig('success')
      this.ModalService.open({
        ...config,
        title: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      })
        .then(response => {
          alert('resolved')
        })
        .catch(err => {
          alert('rejected', err)
        })
    }
    openDangerModal () {
      const config = this.ModalService.generateConfig('danger')
      this.ModalService.open({
        ...config,
        title: 'Lorem ipsum dolor sit amet',
        body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      })
        .then(response => {
          alert('resolved')
        })
        .catch(err => {
          alert('rejected', err)
        })
    }
  }
}

export default DemoComponentsModalComponent
