import DemoComponentsIconsComponent from './components-icons.component'

const DemoComponentsIconsModule = angular.module('cdbl.components-icons', [

])
  .component('cdblDemoComponentsIcons', DemoComponentsIconsComponent)
  .config(($stateProvider) => {
    'ngInject'
    $stateProvider
      .state('components.icons', {
        url: '/icons',
        template: '<cdbl-demo-components-icons></cdbl-demo-components-icons>',
        resolve: {
          redirectIfNotDevelopment: ($state, UIService) => {
            'ngInject'
            if (!UIService.isDevelopment) {
              $state.go('dashboard.list', { listId: 'in-progress' })
            }
          }
        },
        layout: 'new'
      })
  })
  .name

export default DemoComponentsIconsModule
