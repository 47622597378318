import templateUrl from './components-section.html'
import './components-section.scss'

const DemoComponentsSectionComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsSectionComponent {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsSectionComponent'
    }

    $onInit () {
      this.isCollapsed = false
      this.sectionGroup = [
        { isCollapsed: false },
        { isCollapsed: false },
        { isCollapsed: false },
        { isCollapsed: false },
        { isCollapsed: false },
        { isCollapsed: false },
        { isCollapsed: false }
      ]
    }

    toggleAll () {
      this.toggleAllState = this.toggleAllState || false
      this.toggleAllState = !this.toggleAllState
      this.sectionGroup.forEach(item => {
        item.isCollapsed = this.toggleAllState
      })
    }
  }
}

export default DemoComponentsSectionComponent
