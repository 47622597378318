import templateUrl from './components-container.html'
import './components-container.scss'

const DemoComponentsContainerComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsContainerComponent {
    constructor () {
      'ngInject'
      this._identify = 'DemoComponentsContainerComponent'
    }

    $onInit () {

    }
  }
}

export default DemoComponentsContainerComponent
