import templateUrl from './devbar.html'
import './devbar.scss'

const DevbarComponent = {
  bindings: {},
  templateUrl,
  controller: class DevbarComponent {
    constructor ($window, $rootScope, StorageService, Configuration, ModalService, Modal, UserService, UIService, NoticebarService, ProjectService, DevService, ProjectPresetsService) {
      'ngInject'
      this._identify = 'DevbarComponent'

      this.$window = $window
      this.$rootScope = $rootScope
      this.StorageService = StorageService

      this.Configuration = Configuration
      this.ModalService = ModalService
      this.Modal = Modal
      this.UserService = UserService
      this.UIService = UIService
      this.NoticebarService = NoticebarService
      this.ProjectService = ProjectService
      this.DevService = DevService
      this.ProjectPresetsService = ProjectPresetsService
    }

    $onInit () {
      this.visible = this.StorageService.get('devbar') || false
    }

    hideDevbar () {
      this.visible = false
      this.StorageService.set('devbar', this.visible)
    }

    toggleSkeleton () {
      const el = this.$window.document.querySelector('body') // .toggleClass('show-skeleton')
      el.classList.toggle('show-skeleton')
    }

    openApiEndpointsReportModal ($event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'dev-modal',
        template: `<cdbl-dev-modal entities="$ctrl.modal.entities"></cdbl-dev-modal>`,
        entities: {
          apiEndpointsReport: {
            name: 'apiEndpointsReport',
            items: [{ url: 'kaboom', attributes: ['email', 'password'] }]
          }
        }
      })
    }

    openProjectDevModal ($event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'dev-modal',
        template: `<cdbl-dev-modal entities="$ctrl.modal.entities"></cdbl-dev-modal>`,
        entities: {
          project: this.ProjectService.project
        }
      })
    }

    openUserDevModal ($event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'dev-modal',
        template: `<cdbl-dev-modal entities="$ctrl.modal.entities"></cdbl-dev-modal>`,
        entities: {
          user: this.UserService.user
        }
      })
    }


    toggleSidebar (id) {
      const otherSidebarId = id === 'left' ? 'right' : 'left'
      const otherSidebarCtrl = this.UIService.getComponent(`sidebar-main-${otherSidebarId}`)
      if (otherSidebarCtrl) {
        otherSidebarCtrl.close()
      }


      const sidebarCtrl = this.UIService.getComponent(`sidebar-main-${id}`)
      if (sidebarCtrl) {
        sidebarCtrl.toggle()
      }
    }

    openPersonaSurveyModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        class: 'modal__persona-survey',
        template: `<cdbl-persona-survey-modal></cdbl-persona-survey-modal>`,
      })
    }

    showNoticeMessage () {
      this.NoticebarService.open({
        template: `
          <span>Notice content: {{$ctrl.notice.message}}</span> <a ng-click="$ctrl.noticeClose()">Close</a>
        `,
        message: `hello from notice message this should be a long sentence so we can test how it looks like on mobile in several rows`,
        customCallback: () => {
          alert('notice custom callback')
        }
      })
    }


    openClientProfileModal () {
      const project = this.ProjectService.project
      if (project) {
        this.ModalService.open({
          closeOnBackdrop: true,
          closeIcon: true,
          class: 'client-profile-view-modal cdbl-modal--style-with-actions-dividers cdbl-modal--style-with-sticky-header-and-footer',
          template: `<cdbl-client-profile-view-modal project="$ctrl.modal.project"></cdbl-client-profile-view-modal>`,
          project: project
        })
      }
    }
  },
  require: {
    AppCtrl: '^app'
  }
}

export default DevbarComponent
