import templateUrl from './components-page.html'
import './components-page.scss'

const DemoComponentsPageComponent = {
  bindings: {},
  templateUrl,
  controller: class DemoComponentsPage {
    constructor (UIService, $timeout) {
      'ngInject'
      this._identify = 'DemoComponentsPageComponent'
      this.UIService = UIService
      this.$timeout = $timeout
    }

    $onInit () {

    }
  }
}

export default DemoComponentsPageComponent
